.section__head {
  background: var(--highlight-primary-1);
  background: linear-gradient(
    115deg,
    var(--highlight-primary-1) 0%,
    var(--highlight-primary-2) 100%
  );
  position: relative;
  padding: 12.5px 0;

  @media print {
    text-align: center;
    background: none;
    color: var(--text-1);
    padding: 0;
    display: none;
  }

  .head__icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--highlight-primary-3);
  }

  h2 {
    text-transform: uppercase;
    color: var(--elevation-1);
    letter-spacing: 0.05em;
    width: 100%;
    text-align: left;
    padding: 0 20px;

    font-size: 14px;
    @include body-bold;

    @media screen and (min-width: 1000px) {
      font-size: 17px;
    }

    @media print {
      font-size: 34px;
      text-align: center;
      color: var(--text-1);
      padding: 0;
      padding-top: 50px;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    @include body-regular;

    @media print {
      display: none;
    }
  }

  &.icon h2 {
    padding-left: 60px;
  }
}
