button.base {
  background: none;
  border: none;
  color: var(--highlight-primary-1);
  font-size: 12px;
  padding: 7px 0;
  display: inline-block;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    filter: brightness(1.2) !important;
  }
}

button.btn {
  background: none;
  border: 2px solid var(--elevation-1);
  padding: 5px 7px;
  color: var(--elevation-1);
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    filter: brightness(1.2) !important;
  }

  &.tab {
    border: 2px solid var(--text-1);
    text-transform: uppercase;
    color: var(--text-1);
    @include body-bold;
    padding: 5px 20px;
    border-radius: 25px;
    white-space: nowrap;

    &.active {
      background: var(--highlight-primary-1);
      border-color: var(--highlight-primary-1);
      color: var(--elevation-1);
    }
  }

  &.alt {
    border: none;
    color: var(--highlight-primary-1);
  }

  &.no-label {
    height: 25px;
    width: 25px;
    margin: 0;
    position: relative;
    i {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
}

button.btn.tab.is_alternate {
  border: 1px solid var(--elevation-5);
  border-radius: unset;
  margin: -1px -1px -1px auto;
  padding: 10px 10px;
  font-size: 12px;
  position: relative;

  &::before {
    content: "";
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    background: var(--highlight-primary-1);
    opacity: 0;
  }

  &:hover,
  &:focus,
  &:active {
    filter: unset !important;
  }

  &.active {
    background: var(--elevation-1);
    color: var(--text-1);
    box-shadow: var(--shadow);
    border-bottom: 0;
    z-index: 10000;

    &::before {
      opacity: 1;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.ui.dropdown > .text,
.ui.dropdown .menu > .item {
  @include body-regular;
  font-size: 14px;
  white-space: nowrap;
}
