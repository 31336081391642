.pseudo__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50002;
  box-shadow: var(--shadow);
}

html,
body {
  scroll-behavior: smooth;
}

main,
.App {
  height: 100%;
}

.page {
  background: var(--elevation-2);
  padding-top: calc(57px + 40px);
  padding-bottom: 20px;
  min-height: 100vh;

  @media screen and (min-width: 600px) {
    padding-top: calc(74px + 54px);
  }

  @media screen and (min-width: 800px) {
    padding-top: calc(74px + 54px);
    padding-bottom: 40px;
  }

  @media screen and (min-width: 900px) {
    padding-top: calc(76px + 54px);
  }
}

.ribbon__active .page {
  padding-top: calc(57px + 40px + 31px);
  padding-bottom: 20px;
  min-height: 100vh;

  @media screen and (min-width: 600px) {
    padding-top: calc(74px + 54px + 31px);
  }

  @media screen and (min-width: 800px) {
    padding-top: calc(74px + 54px + 31px);
    padding-bottom: 40px;
  }

  @media screen and (min-width: 900px) {
    padding-top: calc(76px + 54px + 31px);
  }
}

.admin__user .page {
  padding-top: calc(57px + 40px + 34px);

  @media screen and (min-width: 600px) {
    padding-top: calc(74px + 54px + 34px);
  }

  @media screen and (min-width: 800px) {
    padding-top: calc(74px + 54px + 3px);
    padding-bottom: 40px;
  }

  @media screen and (min-width: 900px) {
    padding-top: calc(76px + 54px + 34px);
  }
}

.ribbon__active.admin__user .page {
  padding-top: calc(57px + 40px + 34px + 31px);

  @media screen and (min-width: 600px) {
    padding-top: calc(74px + 54px + 34px + 31px);
  }

  @media screen and (min-width: 800px) {
    padding-top: calc(74px + 54px + 3px + 31px);
    padding-bottom: 40px;
  }

  @media screen and (min-width: 900px) {
    padding-top: calc(76px + 54px + 34px + 31px);
  }
}

.section__body {
  border: 1px solid var(--elevation-5);
}

/*
==================================
DASHBOARD LAYOUTS
==================================
*/

.page.admin {
  height: 100%;
  padding: 0;
  min-height: 100vh;
  padding-top: 0 !important;

  .dashboard__main {
    margin-left: 250px;
    padding-top: 42px;
    height: 100%;
    min-height: 100vh;
    // position: relative;
  }

  &.edit__tournament .dashboard__main {
    padding-top: calc(40px + 38px);

    .dashboard__body {
      display: flex;
    }
  }
}

.edit__tournament {
  padding-bottom: 0 !important;
}
