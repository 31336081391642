.page.login {
  height: 100vh;
  position: relative;

  .login__window {
    max-width: 400px;
    width: 100%;
    margin: auto;
    background: white;
    border: 1px solid var(--elevation-5);
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo__container {
    display: flex;
    justify-content: flex-start;
    padding: 20px 20px 0 20px;
    position: relative;

    i {
      position: absolute;
      top: 20px;
      right: 20px;
      color: var(--elevation-6);
    }
  }

  .login__logo {
    display: flex;
    padding: 0;

    .logo {
      width: 250px;
      margin-right: 5px;
    }

    span {
      color: var(--text-1);
      @include body-bold;
      text-transform: uppercase;
      line-height: 1.4em;
      font-size: 17px;

      &.emph {
        color: var(--highlight-primary-3);
      }
    }
  }

  .welcome__container {
    text-align: center;
    padding: 20px 20px 30px 20px;
    margin-top: 10px;

    h3 {
      @include heading-bold;
      font-size: 27px;
      text-transform: uppercase;
      color: var(--highlight-primary-1);
    }
  }

  button {
    background: var(--highlight-primary-1);
    border: none;
    width: 100%;
    padding: 15px 15px;
    color: var(--elevation-1);
    position: relative;
    border-radius: 5px;
    box-shadow: var(--shadow);
    font-size: 17px;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      font-size: 17px;
    }
  }

  .prompt {
    @include body-regular;
    text-align: center;
    display: block;
    margin-top: 7px;
    font-size: 14px;

    .link {
      text-decoration: underline;
      color: var(--highlight-primary-1);
      cursor: pointer;
    }
  }

  .copyright {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100%;

    p {
      text-align: center;
      font-size: 12px;
      color: var(--text-3);

      a {
        text-decoration: underline;
        color: var(--highlight-primary-1);
      }
    }
  }

  .welcome__redirect {
    border-top: 1px solid var(--elevation-5);
    margin-top: -1px;
    background: var(--elevation-3);
    padding: 10px 0;
    @include body-regular;
    display: flex;
    justify-content: space-around;
    a {
      background: none;
      color: var(--text-1);
      padding: 0;
      box-shadow: unset;
      font-size: 14px;
      color: var(--text-3);

      i {
        position: relative;
        top: unset;
        transform: unset;
        transform: translate(4px, 1px);
        color: var(--highlight-primary-1);
        font-size: 12px;
        transition: 0.2s ease-in-out;
      }

      span {
        display: inline-block;
        margin-left: 5px;
        text-decoration: underline;
        color: var(--highlight-primary-1);
      }

      &:hover,
      &:active,
      &:focus {
        i {
          transform: translate(0px, 1px);
        }
      }
    }

    &.forward a {
      i {
        transform: translate(4px, 1px);
      }

      &:hover,
      &:active,
      &:focus {
        i {
          transform: translate(8px, 1px);
        }
      }
    }
  }
}
