/*
========================================
Accordion Header
========================================
*/
.accordion__head {
  background: var(--elevation-1);
  width: 100%;
  border: 1px solid var(--elevation-5);
  margin-top: -1px;
  text-align: left;
  position: relative;
  padding: 15px 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  z-index: 5000;
  border-left: 0;
  border-right: 0;

  &::before {
    content: "";
    height: 2px;
    width: 100%;
    max-width: 0;
    background: var(--highlight-primary-1);
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.2s ease-in-out;
  }

  h3 {
    font-weight: bold;
    color: var(--text-1);
    transition: 0.2s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    h3 {
      color: var(--highlight-primary-1);
    }

    &::before {
      max-width: 100%;
    }
  }

  i {
    margin: 0;
    color: var(--highlight-primary-1);
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 20px;
    transition: transform 0.2s ease-in-out;
  }
}

/*
========================================
Accordion 
========================================
*/

.accordion__section .accordion__body {
  transition: 1s ease-in-out;
  transform-origin: top;
  position: relative;
  bottom: 0;
  padding: 20px;
  overflow: hidden;
  z-index: 4000;
  border-bottom: 1px solid var(--elevation-6);
  background: var(--elevation-1);
}

.accordion__section {
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;

  h3 {
    padding-right: 40px;
    font-size: 14px;
    @include body-bold;
    text-transform: capitalize;
  }

  &.expand {
    .accordion__head {
      box-shadow: var(--shadow);
    }
    .accordion__head::before {
      max-width: 100%;
    }

    .accordion__head h3 {
      color: var(--highlight-primary-1);
    }
  }
  &.collapse {
    .accordion__head i {
      transform: translateY(-50%) rotate(180deg);
    }

    .accordion__body {
      transition: 0.2s ease-in-out;
      position: absolute;
      bottom: 100%;
    }
  }
}

/*
========================================
Accordion - PRINT SETTINGS
========================================
*/

@media print {
  .accordion__section .accordion__body {
    position: relative !important;
    border: none;
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
    background: white;
    margin-top: 7px;
  }
  .accordion__section .accordion__head {
    border: none;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0;
    font-size: 21px;
    box-shadow: none !important;
    text-transform: uppercase;
  }

  .accordion__section .accordion__head i {
    display: none;
  }

  .accordion__section .accordion__head::before {
    display: none;
  }

  .accordion__section .accordion__head h3 {
    color: var(--highlight-primary-2) !important;
    font-size: 20px;
  }
}
