.overview__catalyst__notes {
  position: relative;

  .tab__container {
    display: flex;
    flex-direction: column;
  }

  h5.round__title {
    display: none;
    background: none;
    background-color: white;
    border: none;
    color: var(--highlight-primary-2);
    padding-bottom: 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
    @include body-bold;
  }

  .tab__body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 930px) {
      min-height: 300px;
    }
  }
}

/*
==================================
GROUPING CARDS
==================================
*/
.overview__catalyst__notes .tab__body .player__lists {
  display: block;
  max-height: 350px;

  &.hidden {
    display: none;
  }
}
.grouping__card {
  border: 1px solid var(--elevation-6);
  margin: -1px -1px 0px -1px;
  position: relative;
  background: var(--elevation-1);

  .card__prompt {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate(0, -50%);
    transition: 0.2s ease-in-out;

    i {
      color: var(--text-3);
    }
  }

  button {
    background: none;
    border: none;
    text-align: left;
    padding: 10px 14px;
    padding-right: 30px;
    cursor: pointer;
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      .card__prompt {
        transform: translate(5px, -50%);
      }
    }
  }

  .card__content {
    h4 {
      @include body-bold;
      text-transform: uppercase;
      font-size: 14px;
      background: var(--elevation-4);
      display: inline-block;
      padding: 5px;
      border-radius: 2px;
      margin-bottom: 7px;
    }

    span {
      @include body-regular;
    }
  }
}

/*
==================================
GROUPINGS NOTES
==================================
*/

.player__groupings__notes {
  display: flex;
  flex-direction: column;
  height: 100%;

  .groupings__header {
    display: flex;
    border-bottom: 1px solid var(--elevation-6);
  }

  button.back__prompt {
    cursor: pointer;
    background: none;
    border: none;
    color: var(--text-3);
    border-right: 1px solid var(--elevation-6);
    padding: 7px 7px;
  }

  .header__content {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    h4 {
      @include body-bold;
      text-transform: uppercase;
      font-size: 14px;
      background: var(--elevation-4);
      display: inline-block;
      padding: 5px;
      border-radius: 2px;
      margin: 7px 0;
    }

    p {
      display: inline-block;
      margin-left: 10px;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .groupings__notes {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px 20px 0px;
    height: 100%;
    background: var(--elevation-1);
  }

  .player__notes {
    padding-bottom: 20px;

    h5 {
      @include body-bold;
      margin-bottom: 7px;
      margin-top: -1px;
      text-transform: uppercase;
      color: var(--text-1);
      position: sticky;
      top: -1px;
      background: white;
      padding: 10px 20px;
      border-bottom: 1px solid var(--elevation-6);
      border-top: 1px solid var(--elevation-6);

      @media print {
        display: none;
      }
    }
  }

  .note__grouping {
    // margin-left: 10px;
    padding: 0 20px 0px 20px;

    h6 {
      @include body-bold;
      color: var(--highlight-primary-1);
    }

    ul {
      margin-top: 3px;
      margin-left: 30px;
      list-style-type: disc;

      li {
        @include body-regular;
        margin-bottom: 7px;
        line-height: 1.5em;
        font-size: 14px;
      }

      ul > li:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media print {
    break-inside: avoid;
  }
}

/*
==================================
PRINT QUERIES
==================================
*/

@media print {
  .overview__catalyst__notes {
    max-height: 100% !important;
    height: 100% !important;
    border: none;
    margin-top: 0;

    .tab__body {
      height: unset !important;
      overflow-y: unset !important;
      overflow-x: unset !important;
    }

    .tab__content {
      display: none;

      &.active {
        display: block;

        @media print {
          page-break-after: unset !important;
          // break-before: page !important;
          page-break-inside: unset;
        }
      }
    }

    .grouping__card {
      border: none !important;

      br {
        display: none;
      }

      .card__prompt {
        display: none;
      }
    }

    h5.round__title {
      display: block;
    }

    .groupings__header {
      display: none;
    }

    .groupings__notes {
      height: unset !important;
      overflow-y: unset !important;
      overflow-x: unset !important;
      padding: 0 20px;

      .note__grouping {
        h6 {
          font-size: 17px;
          padding-top: 10px;
        }
        ul li {
          font-size: 17px;
          page-break-inside: avoid !important;
          margin-bottom: 0;
          margin-top: 10px;
        }
      }

      .player__notes {
        // break-before: page !important;
        page-break-inside: avoid;
        padding: 0;
      }
    }

    .player__groupings__notes .player__notes h5 {
      border: none;
      font-size: 21px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
