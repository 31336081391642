.section__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.section__container.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section__body {
  padding: 10px;
  background: var(--elevation-1);
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &.no__padding {
    padding: 0;
  }

  &.no__border {
    border: none !important;
  }

  &.clamp__height {
    max-height: 300px;
  }

  .empty__content {
    min-height: 200px;
    height: 100%;
    position: relative;
  }

  @media print {
    max-height: unset !important;
    overflow: visible;
    padding: 0 35px !important;
    border: none !important;
    height: auto !important;
  }

  @media screen and (min-width: 500px) {
    padding: 20px;
  }
}

.section__resource {
  display: flex;
  flex-direction: column;
  background: var(--elevation-1);
  border: 1px solid var(--elevation-5);
  margin-top: -1px;
  padding: 10px;
  grid-gap: 10px;

  @media screen and (min-width: 500px) {
    padding: 20px;
    grid-gap: 20px;
  }

  @media screen and (min-width: 1000px) {
    display: grid;
    grid-auto-rows: minmax(200px, max-content);
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1300px) {
    grid-template-columns: 4fr 6fr;
  }

  @media screen and (min-width: 1500px) {
    padding: 40px;
    grid-gap: 40px;
  }
}

.tournament__resources__container {
  @include wrap;
  margin-top: 20px;

  @media screen and (min-width: 1500px) {
    margin-top: 40px;
  }
}
