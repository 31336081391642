.rich-text-error {
  background: #fceaea;
  padding: 12px 12px !important;
  border-radius: 3px;
  h5 {
    @include heading-bold;
    font-family: 15px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: var(--danger-1);
    margin-bottom: 6px;
  }

  button {
    background: none;
    border: none;
    display: inline;
    text-decoration: underline;
    color: var(--highlight-primary-1);
    cursor: pointer;

    i {
      text-decoration: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--highlight-primary-2);
    }
  }
}
