button.submit {
  position: relative;

  .initializing__loader {
    position: absolute;
    top: 3px;
    right: 3px;
  }
}

.dotcom__promo {
  padding: 10px;
  border-bottom: 1px solid var(--elevation-5);
  margin: 0 5px;

  @media screen and (min-width: 500px) {
    padding: 15px;
  }

  @media screen and (min-width: 1000px) {
    padding: 25px;
  }

  @media print {
    page-break-inside: avoid;
    padding: 20px 0;
  }

  h5 {
    @include body-bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .dotcom__promo__head {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

    .initializing__loader {
      position: absolute;
      top: -5px;
      right: 45px;
    }

    @media screen and (min-width: 500px) {
      flex-direction: row;
    }

    a {
      @include body-regular;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      line-height: 14px;
      color: var(--highlight-primary-1);
      display: inline-block;
      margin-top: 10px;
      white-space: nowrap;

      @media screen and (min-width: 500px) {
        flex-direction: row;
        margin-top: 0;
      }

      @media print {
        display: none;
      }

      i {
        margin: 0;
        padding: 0;
      }
    }
  }

  .promo__title {
    display: flex;
    margin-right: 20px;

    .position {
      @include body-regular;
      display: block;
      background: var(--highlight-primary-1);
      color: var(--elevation-1);
      font-size: 21px;
      padding: 12px 15px;
      margin-right: 10px;

      @media screen and (min-width: 500px) {
        font-size: 25px;
      }
    }

    .title .sub {
      @include body-bold;
      text-transform: uppercase;
      font-size: 12px;
      color: var(--text-3);
      @media screen and (min-width: 500px) {
        font-size: 14px;
      }
    }

    h5 {
      @include body-bold;
      text-transform: uppercase;
      margin-top: 10px;
      font-size: 17px;
      margin-bottom: 0;
      line-height: 1.2em;

      @media screen and (min-width: 500px) {
        font-size: 21px;
      }
    }
  }

  .dotcom__promo__content {
    h6 {
      @include body-bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
}

.promos__container {
  max-height: 300px;
  // overflow-y: auto;

  &.preview {
    max-height: unset;
    overflow-y: visible;
  }

  @media print {
    max-height: unset;
    height: 100%;
    overflow-y: visible;
  }
}

.promos__container > li:last-child {
  border: none;
  margin-bottom: 0;
}

.dotcom__promo.preview {
  margin: 0;
  padding: 20px 20px;

  position: relative;

  .dotcom__promo__head {
    margin-bottom: 20px;
    padding-right: 75px;
  }

  .promo__title h5 {
    font-size: 17px;
    margin-top: 5px;
  }

  .promo__title .title .sub {
    font-size: 12px;
  }

  .dotcom__promo__content h6 {
    font-size: 12px;
  }

  .item__actions {
    position: absolute;
    right: 7px;
    top: 15px;
    z-index: 499;

    button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      margin-left: 10px;
      cursor: pointer;
      color: var(--text-3);
      transition: 0.2s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        color: var(--highlight-primary-1);
      }
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.75);
    display: flex;
    align-items: center;
    border: 1px solid var(--text-3);
    z-index: 500;
    p {
      text-align: center;
      color: var(--elevation-1);
      width: 100%;
    }

    button {
      border: none;
      background: none;
      color: var(--elevation-1);
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
    }
  }
}

.promo__preview {
  border: 1px solid var(--elevation-5);
  margin: 5px;
  padding: 10px 10px;
  padding-right: 100px;
  position: relative;

  .button__actions {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
      cursor: pointer;
      background: none;
      border: none;

      i {
        margin: 0;
        padding: 0;
        color: var(--text-3);
      }
    }

    button:hover,
    button:focus,
    button:active {
      i {
        color: var(--highlight-primary-1);
      }
    }
  }

  .position {
    @include body-bold;
    background: var(--highlight-primary-1);
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    color: var(--elevation-1);
    font-size: 14px;
  }

  .title {
    display: inline-block;
    @include body-bold;
    margin-left: 10px;
    font-size: 17px;
    text-transform: uppercase;
  }

  .promo__content {
    margin-top: 10px;

    label {
      @include body-bold;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
