.resource__nav {
  background: var(--highlight-primary-1);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 10001;
  box-shadow: var(--shadow);
  @include gradient-header;

  @media screen and (min-width: 1200px) {
    display: none;
  }

  button.toggle {
    font-size: 17px;
    color: var(--elevation-1);
    line-height: 49px;
    text-align: center;
    text-transform: uppercase;
    @include body-bold;
    background: none;
    border: none;
    width: 100%;
    position: relative;

    i {
      margin: 0;
      padding: 0;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.resource__nav .contents {
  max-height: 45vh;
  overflow-y: auto;
  padding-top: 10px;

  .section h3 {
    margin-left: 10px;
    @include body-bold;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--elevation-1);
  }
}

.resource__nav nav {
  position: fixed;
  bottom: calc(15px + 50px + 10px);
  right: 15px;
  background: var(--highlight-primary-2);
  border-radius: 5px;
  box-shadow: var(--shadow);
  border: 1px solid var(--highlight-primary-1);
  border-top: var(--highlight-primary-3) 4px solid;
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.default {
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out,
      visibility 0.2s ease-in-out 0.2s;
  }

  h2 {
    padding: 10px;
    text-transform: uppercase;
    color: var(--elevation-1);
    background: var(--highlight-primary-2);
    font-size: 14px;

    border-bottom: var(--highlight-primary-1) 1px solid;
  }

  ul {
    padding: 10px;
  }

  button {
    background: none;
    border: none;
    padding: 7px 0 7px 15px;
    margin: 0;
    font-size: 14px;
    border-left: 1px solid var(--highlight-primary-3);
    color: var(--elevation-5);
    transition: 0.2s ease-in-out;
    white-space: nowrap;

    &:active,
    &:hover,
    &:focus {
      border-left: 5px solid var(--highlight-primary-3);
      padding: 7px 0 7px 10px;
    }
  }
}

.admin__user .section__anchor__main {
  position: absolute;
  bottom: calc(100% + (34px + 57px + 40px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (34px + 74px + 54px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (35px + 76px + 54px));
  }
}

.admin__user.ribbon__active .section__anchor__main {
  bottom: calc(100% + (34px + 57px + 40px + 31px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (34px + 74px + 54px + 31px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (35px + 76px + 54px + 31px));
  }
}

.default__user .section__anchor__main {
  position: absolute;
  bottom: calc(100% + (57px + 40px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (74px + 54px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (76px + 54px));
  }
}

.default__user.ribbon__active .section__anchor__main {
  bottom: calc(100% + (57px + 40px + 31px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (74px + 54px + 31px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (76px + 54px + 31px));
  }
}
