.pagination {
  margin-top: 10px;

  @media screen and (min-width: 767px) {
    margin-top: 20px;
  }
  button {
    width: 25px !important;
    height: 25px !important;
    // line-height: 17px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    border: none;
    background: none;
    margin: 0 5px;
    position: relative;
    padding: 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--text-1);
    }

    @media screen and (min-width: 600px) {
      width: 30px;
      height: 30px;
      line-height: 25px;
      font-size: 14px;
    }

    &.current__page {
      border: 2px solid var(--highlight-primary-1);
    }
  }
  button {
    cursor: pointer;
  }
  .prev,
  .next {
    background: var(--highlight-primary-1);
    border: none;

    i {
      margin: 0;
      padding: 0;
      color: var(--elevation-1);
    }
  }

  span.page__skip {
    color: var(--highlight-primary-2);
    font-size: 20px;
    display: inline-block;
    margin: 0 5px;
    transform: translateY(5px);
  }

  & > span.page__skip:first-of-type {
    margin-right: 7px;
  }
}

.explorer__list .pagination {
  margin-top: 30px;

  @media screen and (min-width: 767px) {
    margin-top: 20px;
  }
}
