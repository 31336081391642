.slideshow {
  height: 75vh;
  position: relative;

  & .slick-slider,
  & .slick-list,
  & .slick-track {
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;
  }
}

.slide__container {
  // height: 75vh;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
