.uinotification {
  position: fixed;
  display: block;
  top: 20px;
  right: 20px;
  z-index: 999999999;
  background: var(--elevation-1);
  border: 2px solid var(--highlight-primary-2);
  width: 300px;
  box-shadow: var(--shadow);
  padding: 15px 20px;
  border-radius: 4px;
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.2s ease-in-out;

  &.active {
    transform: translateY(0%);
    opacity: 1;
  }

  .message {
    display: flex;
    font-size: 14px;
    align-items: center;

    p {
      margin-left: 7px;
      font-size: 14px;
    }
  }
}
