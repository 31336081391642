/*
==================================
CONTAINER
==================================
*/

.header__main {
  @include gradient-header;

  @media screen and (min-width: 800px) {
    padding: 10px 0;
  }

  .header__wrap {
    @include wrap;
  }
}

/*
==================================
ADMIN HEADER
==================================
*/

.admin__header {
  background: var(--text-1);
  border-bottom: 3px solid var(--highlight-primary-3);

  .container {
    @include wrap;
    display: flex;
    justify-content: space-between;
  }
}

.admin__links {
  display: flex;
  position: absolute;
  right: 0%;
  top: calc(100% - 3px);
  flex-direction: column;
  background: var(--text-1);
  z-index: 70000;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  a {
    @include body-regular;
    font-size: 14px;
    color: var(--elevation-1);
    white-space: nowrap;
    text-align: right;
    display: inline-block;
    border-bottom: 1px solid var(--text-3);
    padding: 10px;
    transition: 0.2s ease-in-out;

    .link__icon i {
      transform: rotate(-45deg);
    }

    @media screen and (max-width: 699px) {
      &:hover,
      &:active,
      &:focus {
        background: var(--text-2);
      }
    }

    @media screen and (min-width: 700px) {
      padding: 10px 10px;
      padding-right: 0;
      margin-left: 20px;
      border-left: 1px solid var(--text-3);
    }
  }

  @media screen and (max-width: 699px) {
    & > :last-child {
      border: none;
    }
  }

  @media screen and (min-width: 700px) {
    position: relative;
    flex-direction: row;
    background: none;
    align-items: center;
    top: unset;
    right: unset;
    visibility: visible;
    opacity: 1;
  }
}

.admin__links__toggle {
  display: flex;
  background: none;
  border: none;
  align-items: center;
  height: 100%;
  color: var(--elevation-1);
  font-size: 21px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 700px) {
    display: none;
  }

  @media screen and (max-width: 699px) {
    &:hover,
    &:focus,
    &:active {
      & ~ .admin__links {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.admin__menu {
  @media screen and (max-width: 699px) {
    &:hover,
    &:focus,
    &:active {
      & .admin__links {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.admin__greeting {
  @include body-regular;
  display: inline-block;
  border-right: 1px solid var(--text-3);
  font-size: 14px;
  padding: 10px 20px;
  padding-left: 0px;

  span {
    text-transform: capitalize;
  }

  color: var(--elevation-1);
}

/*
==================================
LOGO
==================================
*/

.header__logo {
  display: flex;
  padding: 5px 0;

  @media screen and (min-width: 600px) {
    padding: 10px 0;
  }

  @media screen and (min-width: 800px) {
    padding: 0;
  }

  .logo {
    width: 125px;
    margin-right: 5px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 600px) {
      width: 175px;
    }

    @media screen and (min-width: 1080px) {
      width: 200px;
    }

    // @media screen and (min-width: 900px) {
    //   width: 50px;
    // }

    img {
      max-height: 54px;

      @media screen and (max-width: 600px) {
        max-height: 40px;
      }
    }
  }

  span {
    color: var(--elevation-1);
    @include body-bold;
    text-transform: uppercase;
    line-height: 1.4em;
    font-size: 12px;

    @media screen and (min-width: 600px) {
      font-size: 14px;
    }

    &.emph {
      color: var(--highlight-primary-3);
    }
  }
}

/*
==================================
MENU CONTAINERS
==================================
*/

.header__flex {
  display: flex;
  justify-content: space-between;
  z-index: 5001;
}

.menu__container__mobile {
  padding: 5px 0 5px 10px;
  border-left: 1px solid var(--highlight-secondary-1);
  display: flex;
  align-items: center;

  @media screen and (min-width: 600px) {
    padding: 10px 0 10px 25px;
  }
  @media screen and (min-width: 800px) {
    padding: 0;
    display: none;
  }
}

.admin__user .menu__container {
  @media screen and (min-width: 320px) and (max-width: 600px) {
    top: calc(50px + 34px);
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    top: calc(74px + 34px);
  }
}

.menu__container {
  display: flex;
  position: fixed;
  top: 50px;
  right: -250px;
  background: var(--highlight-primary-2);
  border-left: 1px solid var(--highlight-secondary-1);
  border-top: 1px solid var(--highlight-secondary-1);
  z-index: 5000;
  height: 100%;
  width: 250px;
  // box-shadow: var(--shadow);
  transition: 0.2s ease-in-out;

  &.active {
    right: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background: var(--highlight-primary-1);

    @media screen and (min-width: 800px) {
      background: none;
    }
  }

  @media screen and (min-width: 600px) {
    top: 74px;
  }

  @media screen and (min-width: 800px) {
    position: relative;
    top: unset;
    right: unset;
    border: unset;
    background: unset;
    width: auto;
    height: unset;
    align-items: center;
  }
}

/*
==================================
HAMBURGER ICON
==================================
*/
.menu__icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  margin: auto;

  @media screen and (min-width: 600px) {
    height: 54px;
  }
}

.hamburger {
  width: 35px;
  height: 2px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}

.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  width: 35px;
  height: 2px;
  background: white;
  transition: 0.5s;
}

.hamburger:before {
  top: -7px;
}

.hamburger:after {
  top: 7px;
}

.menu__icon.active .hamburger {
  box-shadow: 0px 0px 0px grey;
  background: rgba(0, 0, 0, 0);
}

.menu__icon.active .hamburger:before {
  top: 0;
  transform: rotate(45deg);
}

.menu__icon.active .hamburger:after {
  top: 0;
  transform: rotate(135deg);
}

.header__menu {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  a,
  button {
    display: flex;
    align-items: center;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    &.button__icon .label {
      display: inline-block;
      width: 100%;
      @media screen and (min-width: 800px) {
        display: none !important;
      }
    }

    &.button__icon.notifs .icon {
      position: relative;
      margin-left: 7px;

      .indicator {
        width: 12px;
        height: 12px;
        background: var(--highlight-primary-3);
        position: absolute;
        top: 7px;
        right: 5px;
        border: 2px solid var(--highlight-primary-2);
        border-radius: 50%;
      }
    }

    &.button__icon.notifs i {
      font-size: 20px;
      transform: rotate(45deg);
      color: var(--elevation-1);

      @media screen and (min-width: 800px) {
        font-size: 25px;
      }
    }
  }

  a,
  li button .label {
    @include body-bold;
    color: var(--elevation-1);
    display: flex;
    align-items: center;
    padding: 17px 17px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
    font-size: 14px;

    @media screen and (min-width: 800px) and (max-width: 1000px) {
      font-size: 12px;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    &.button__icon .label {
      display: inline-block;
      width: 100%;
      @media screen and (min-width: 800px) {
        display: none !important;
      }
    }

    &.button__icon.notifs .icon {
      position: relative;
      margin-left: 7px;

      .indicator {
        width: 12px;
        height: 12px;
        background: var(--highlight-primary-3);
        position: absolute;
        top: 7px;
        right: 5px;
        border: 2px solid var(--highlight-primary-2);
        border-radius: 50%;
      }
    }

    &.button__icon.notifs i {
      font-size: 20px;
      transform: rotate(45deg);
      color: var(--elevation-1);

      @media screen and (min-width: 800px) {
        font-size: 25px;
      }
    }
  }
}

ul.menu li {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  border-bottom: 1px solid var(--highlight-secondary-1);

  &:active,
  &:focus,
  &:hover {
    background: var(--highlight-secondary-1);
    transition: 0.2s ease-in-out;

    @media screen and (min-width: 800px) {
      background: none;
    }
  }

  @media screen and (min-width: 800px) {
    margin-left: 20px;
    border: none;
  }

  @media screen and (min-width: 900px) {
    margin-left: 30px;
  }
  &.profile {
    cursor: alias;
    i {
      font-size: 20px;
      padding: 0;
      margin: 0;
      margin-left: 7px;
      line-height: 17px;
    }

    div.label {
      display: flex;
      align-items: center;
    }

    span {
      // line-height: 20px;
      display: flex;
      align-items: center;
    }
  }

  div.active__indicator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 10px;
    height: 10px;

    border-radius: 50%;
    border: 1px solid var(--elevation-2);

    &.external {
      border: none;
      color: var(--elevation-1);
    }

    &.active {
      background: var(--elevation-1);
    }

    @media screen and (min-width: 800px) {
      display: none;
    }
  }
}

.item__icon {
  i {
    margin-right: 10px;
    font-size: 20px;
  }
}

/*
==================================
SUB-MENU ITEMS
==================================
*/

li.has__child {
  position: relative;
  border-left: 3px solid var(--highlight-primary-3);

  @media screen and (min-width: 800px) {
    border-left: none;
  }

  & > .active__indicator {
    display: none;
  }

  ul {
    @media screen and (min-width: 800px) {
      position: absolute;
      top: 100%;
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
      border: 1px solid var(--highlight-primary-2);
      border-radius: 2px;
      box-shadow: var(--shadow);
      border-top: 3px solid var(--highlight-primary-3);
      padding: 0;
      margin: 0;
    }

    li {
      background-color: var(--highlight-primary-2);
      border-bottom: none;
      border-top: 1px solid var(--highlight-primary-2);

      button {
        padding: 0;
      }

      .label,
      a {
        padding-left: 40px;
        width: 100%;
        border-bottom: none;
      }

      @media screen and (min-width: 800px) {
        border-bottom: 1px solid var(--highlight-primary-2);
        width: 100%;
        margin: 0;
        padding: 0;

        button {
          padding: 0;
          width: 100%;
        }

        .label,
        a {
          padding: 10px 20px !important;
          background: var(--highlight-primary-1);
          width: 100%;
          text-align: center;
          transition: 0.2s ease-in-out;

          &:hover,
          &:focus,
          &:active {
            background: var(--highlight-primary-2);
          }
        }
      }
    }

    & > li:last-child {
      border-bottom: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    ul {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
