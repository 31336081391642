.dashboard__header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 250px);
  background: var(--highlight-primary-1);
  z-index: 70000;
  display: flex;
  justify-content: space-between;

  .view__live {
    cursor: pointer;
    color: var(--elevation-1);
    font-size: 14px;
    padding: 10px 20px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    @include body-regular;

    i {
      margin-right: 7px;
    }
  }

  i {
    font-size: 20px;
  }

  li {
    padding: 10px 20px;

    label {
      display: flex;
      align-items: center;
    }
  }

  li label.user span {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--elevation-1);
    font-size: 14px;
  }

  li.has__child ul {
    right: 5%;
  }
}

.dashboard__sidebar {
  position: fixed;
  height: 100%;
  background: var(--elevation-1);
  border-right: 1px solid var(--elevation-5);
  width: 250px;
  z-index: 50000;

  .sidebar__header {
    margin: 10px 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--elevation-5);
    span {
      color: var(--text-1);

      &.emph {
        color: var(--highlight-primary-3);
      }
    }
  }

  .sidebar__menu {
    padding: 0 20px;
    margin-top: 40px;

    li {
      @include body-regular;
      color: var(--text-1);
      margin-top: 10px;

      i {
        margin-right: 20px;
      }

      a {
        display: block;
        color: var(--text-1);
        padding: 10px;
        border-radius: 3px;
        transition: 0.2s ease-in-out;
      }
    }

    li:hover,
    li:focus {
      a {
        background: var(--elevation-2);
        color: var(--text1);
      }
    }

    li.active {
      a {
        color: var(--elevation-1) !important;
        background: var(--highlight-primary-1) !important;
      }
    }
  }
}

.staff__dashboard {
  margin: 0 20px;
  padding: 20px 0px;
}
