.links__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  &.col2 {
    @media screen and (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .document {
        margin-bottom: 0 !important;
      }
    }
  }

  & > :last-child {
    margin-bottom: 0 !important;
  }
}

.link__card {
  margin-bottom: 5px;
  position: relative;
  padding: 5px;
  a {
    display: flex;

    &:hover,
    &:focus,
    &:active {
      h3 i {
        color: var(--highlight-primary-1) !important;
      }
    }
  }

  .link__loader {
    position: absolute;
    top: 3px;
    right: 3px;
  }

  .delete__file {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: var(--text-3);
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;

    &:hover > i {
      color: var(--highlight-primary-1);
    }
  }

  .link__icon {
    display: block;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    background: var(--highlight-primary-1);
    border-radius: 50%;
    position: relative;

    i {
      color: var(--elevation-1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .link__content {
    margin-left: 10px;
    h3 {
      @include body-bold;
      text-transform: uppercase;
      color: var(--text-1);
      font-size: 14px;
      line-height: 1.2em;

      i {
        color: var(--elevation-6);
      }

      @media screen and (min-width: 1000px) {
        font-size: 15px;
      }
    }

    .link__url {
      @include body-regular;
      font-size: 12px;
      color: var(--highlight-primary-1);
      text-decoration: underline;
      line-height: 1.4em;
      word-break: break-all;
    }
  }
}

.links__container.edit {
  padding: 10px 0;
  .link__card {
    border: 1px solid var(--elevation-5);
    margin: 0 10px 10px 10px;
    padding: 10px;
  }
}

.memorandums .link__card {
  border: 1px solid var(--elevation-5);
  padding: 10px;
  border-left: solid 4px var(--highlight-primary-1);
}
