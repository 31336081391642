.urgent__messages {
  margin-bottom: 20px;

  .tab__body {
    padding: 25px;
    overflow-y: auto;
    height: 100%;
    min-height: 150px;

    .document__container {
      padding: 0 !important;

      .document {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 930px) {
      max-height: 450px;
    }
  }

  @media screen and (min-width: 1500px) {
    margin-bottom: 40px;
  }
}
