@mixin logo-type {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

@mixin heading-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin heading-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin body-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin body-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/*----------------------------------------------
  # HEADINGS
  ------------------------------------------------*/

* h1 {
  @include heading-regular;
  font-size: 21px;
  color: var(--text-1);
  line-height: 1.2em;
  margin: 0;
}

* h2 {
  @include heading-regular;
  font-size: 17px;
  color: var(--text-1);
  line-height: 1.4em;
}

/*----------------------------------------------
  # PARAGRAPHS
  ------------------------------------------------*/

* p {
  @include body-regular;
  line-height: 1.4em;
  color: var(--text-1);
  font-size: 17px;
}

* p.mime {
  @include body-regular;
  line-height: 1.75em;
  color: var(--text-1);
  font-size: 14px;
}
