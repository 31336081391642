.print__title {
  display: none;

  h4 {
    @include heading-bold;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 27px;
    padding-bottom: 7px;
    color: var(--highlight-primary-1);
    margin-top: 30px;
  }

  @media print {
    display: block;
    background: none;
    background-color: white;
    border: none;
    color: var(--highlight-primary-2);
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.tab__print__title {
  padding: 10px 20px;
  text-transform: uppercase;
  background: var(--highlight-primary-1);
  border-bottom: 2px solid var(--highlight-primary-3);
  color: var(--elevation-1);
  margin-top: 40px;
  display: none;

  @media print {
    display: block;
    background: none;
    background-color: white;
    border: none;
    color: var(--highlight-primary-2);
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;

    span {
      font-size: 27px;
      display: block;
    }
  }
}

.resource__nav h3 {
  @include heading-bold;
  color: var(--elevation-1);
  margin-left: 5px;
  text-transform: uppercase;
  margin-top: 10px;
}
