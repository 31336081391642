/*
==================================
NORMALIZE
==================================
*/
@import "./normalize/forms";
@import "./normalize/resets";
@import "./normalize/layouts";

/*
==================================
VARIABLES
==================================
*/
@import "./variables/variables";
@import "./variables/typography";

/*
==================================
COMPONENTS
==================================
*/
@import "./components/header";
@import "./components/sectionhead";
@import "./components/buttons";
@import "./components/tabs";
@import "./components/accordions";
@import "./components/scrollingticker";
@import "./components/slideshow";
@import "./components/pagination";
@import "./components/footer";
@import "./components/document";
@import "./components/preloader";
@import "./components/forms";
@import "./components/texteditor";
@import "./components/emptydialogue";
@import "./components/loadingdialogue";
@import "./components/uinotification";
@import "./components/tooltip";
@import "./components/staticlinks";
@import "./components/richtext";
@import "./components/sections";
@import "./components/titles";
@import "./components/ribbon";
@import "./components/richtexterror";

/*
==================================
FEATURE SPECIFIC COMPONENTS
==================================
*/

// TOURNAMENT EXPLORER
@import "./features/tournamentexplorer/tournamentexplorer";

// TOURNAMENT DETAILS
@import "./features/tournamentdetail/tournamentheader";
@import "./features/tournamentdetail/tournamentoverview";
@import "./features/tournamentdetail/tournamentnavigator";
@import "./features/tournamentdetail/resourcenav";
@import "./features/tournamentdetail/dotcomlowerthirds";
@import "./features/tournamentdetail/catalystnotes";
@import "./features/tournamentdetail/urgentmessages";

// TOURNAMENT LIST
@import "./features/tournamentlist/tournamentslist";
@import "./features/tournamentlist/tournamentstoolbar";

// EDIT TOURNAMENT
@import "./features/edittournament/edittoolbar";
@import "./features/edittournament/editsidebar";
@import "./features/edittournament/editbody";
@import "./features/edittournament/fileuploads";

/*
==================================
PAGES
==================================
*/
@import "./pages/login";
@import "./pages/notfound";
@import "./pages/dashboard";

/*
==================================
3RD PARTY PACKAGE OVERRIDES
==================================
*/

.ui.dropdown > .text,
.ui.dropdown .menu > .item {
  @include body-regular;
  font-size: 14px;
  white-space: nowrap;
  line-height: 1.3em;
}
