.edit__toolbar {
  position: fixed;
  top: 42px;
  left: 250px;
  background: var(--text-1);
  border-bottom: 3px solid var(--highlight-primary-3);
  width: calc(100% - 250px);
  display: flex;
  justify-content: space-between;
  z-index: 600000;

  .breadcrumbs {
    @include body-regular;
    padding: 3px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-right: 1px solid var(--text-3);

    i {
      color: var(--elevation-1);
    }

    span {
      color: var(--elevation-1);
    }

    a,
    a i,
    a span {
      color: var(--elevation-6);
      text-decoration: underline;
    }
  }

  .tools {
    display: flex;

    a {
      display: flex;
      align-items: center;
      color: var(--elevation-1);
      @include body-regular;
      font-size: 14px;
      padding: 10px 10px;
      border-left: 1px solid var(--text-3);
      white-space: nowrap;

      i {
        transform: rotate(-45deg);
      }
    }
  }

  .select__form {
    border-left: 1px solid var(--text-3);
    width: 100%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    i {
      color: var(--success-1);
    }
  }

  .select__form .ui.dropdown {
    @include body-regular;
    background: var(--text-1);
    color: var(--elevation-1) !important;
    font-size: 14px;
    padding-left: 5px;
    outline: none !important;
    border-color: var(--text-1) !important;
    border-radius: none !important;

    & > .text {
      color: var(--elevation-1) !important;
    }

    i {
      color: var(--elevation-1);
    }

    .visible.menu {
      background: var(--text-1);
      border-radius: 0;

      .item {
        border-color: var(--text-3);
        padding-left: 10px !important;
      }

      .text {
        color: var(--elevation-1) !important;
        font-size: 14px;
      }
    }
  }

  button.save {
    display: flex;
    white-space: nowrap;
    align-items: center;
    background: none;
    border: none;
    font-size: 14px;
    color: var(--elevation-1);
    padding: 0 10px;
    border-left: 1px solid var(--text-3);
    cursor: pointer;

    i {
      margin-right: 7px;
    }
  }
}
