.document__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  position: relative;

  &.col2 {
    @media screen and (min-width: 1300px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .document {
        margin-bottom: 0 !important;
      }
    }
  }

  &.col2__wide {
    @media screen and (min-width: 700px) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: 10px;

      .document {
        margin-bottom: 0 !important;
      }
    }
  }

  & > :last-child {
    margin-bottom: 0 !important;
  }

  @media print {
    display: none;
  }
}

.document {
  display: flex;
  margin: 0 0 20px 0;
  padding: 5px;
  // margin-left: 10px !important;

  &:hover,
  &:focus,
  &:active {
    .document__icon i {
      color: var(--highlight-primary-2) !important;
    }

    a.document__meta span.file__name,
    span.document__meta span.file__name {
      color: var(--highlight-primary-2);
    }
  }

  .document__icon {
    margin-right: 10px;

    .icon {
      position: relative;
      display: block;
      width: auto;
    }

    .document__extension {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include body-regular;
      font-size: 10px;
      color: var(--elevation-1);
      z-index: 500;
      font-size: 9px;
    }
  }

  i {
    font-size: 35px;
    color: var(--highlight-primary-1);
    padding: 0;
    margin: 0;
    transition: 0.2s ease-in-out;
  }

  a.document__meta,
  span.document__meta {
    display: block;

    h5 {
      @include body-bold;
      text-transform: uppercase;
      color: var(--text-1);
      font-size: 14px;
      line-height: 1.2em;
      transition: 0.2s ease-in-out;

      @media screen and (min-width: 1000px) {
        font-size: 15px;
      }
    }

    span {
      @include body-regular;
      font-size: 12px;
      color: var(--text-3);
      display: block;
      margin-top: 3px;

      &.file__name {
        font-size: 12px;
        color: var(--highlight-primary-1);
        text-decoration: underline;
        line-height: 1.4em;
        word-break: break-all;
      }
    }
  }
}

.document__container.memorandums {
  .document {
    border: 1px solid var(--elevation-6);
    padding: 15px 15px 15px 19px;
    position: relative;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 4px;
      background: var(--highlight-primary-1);
      top: 0;
      left: 0;
    }
  }
}
