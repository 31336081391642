.ribbon {
  background: var(--text-1);
  padding: 5px 6px;
  border-bottom: 1px solid var(--text-3);
  overflow-x: auto;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  p {
    color: var(--elevation-1);
    text-align: right;
    @include wrap;
    white-space: nowrap;
    padding-right: 3px;

    i {
      color: var(--highlight-primary-3);
    }

    a {
      text-decoration: underline;
      color: var(--highlight-primary-1);
      filter: brightness(2);
    }
  }
}

.ribbon * {
  font-size: 14px;
}
