.not__found {
  min-height: calc(var(--vh) * 0.75);
  background: var(--elevation-1);
  position: relative;

  .dialogue__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 100px;
      text-align: center;
      margin: 0;
    }

    h3 {
      margin: 0;
      font-size: 21px;
      @include body-bold;
      margin-bottom: 20px;
    }

    p {
      max-width: 320px;
      margin: auto;
      text-align: center;
    }

    a {
      background: var(--highlight-primary-1);
      padding: 10px 15px;
      display: inline-block;
      max-width: 200px;
      text-align: center;
      margin-top: 20px;
      @include body-regular;
      color: var(--elevation-1);
      box-shadow: var(--shadow);
      border-radius: 2px;
    }
  }
}
