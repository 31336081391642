footer {
  @include gradient-header;
  border: none;
  padding: 20px 0;

  @media screen and (min-width: 790px) {
    padding: 60px 0;
  }

  .footer__wrap {
    @include wrap;
  }

  h4 {
    @include body-bold;
    color: var(--elevation-1);
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;

    @media screen and (min-width: 767px) {
      font-size: 17px;
    }
  }

  .footer__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @media screen and (min-width: 790px) {
      margin-bottom: 200px;
    }

    .quick__links {
      @include body-regular;
      text-align: right;

      li {
        margin-bottom: 10px;

        @media screen and (min-width: 767px) {
          margin-bottom: 20px;
        }
      }

      li a {
        color: var(--elevation-1);
        font-size: 14px;

        @media screen and (min-width: 767px) {
          font-size: 17px;
        }
      }
    }
  }

  .footer__bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    @media screen and (min-width: 600px) {
      align-items: flex-end;
    }

    @media screen and (min-width: 790px) {
      flex-direction: row;
    }

    ul {
      display: flex;

      @media screen and (min-width: 600px) {
        justify-content: flex-end;
      }

      li {
        margin-left: 10px;

        @media screen and (min-width: 767px) {
          margin-left: 20px;
        }
      }

      & > li:first-of-type {
        margin-left: 0;
      }
      li a {
        color: var(--elevation-1);
        font-size: 30px;
        background: var(--highlight-primary-2);
        height: 35px;
        width: 35px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        border-radius: 50%;

        i {
          font-size: 20px;
          padding: 0;
          margin: 0;
        }
      }
    }

    p.copyright {
      color: var(--elevation-1);
      margin-top: 40px;
      font-size: 14px;

      @media screen and (min-width: 790px) {
        margin-top: 0;
        font-size: 17px;
      }
    }
  }
}

.footer__logo {
  display: flex;

  .logo {
    width: 175px;
    margin-right: 5px;

    @media screen and (min-width: 767px) {
      width: 300px;
    }
  }

  span {
    color: var(--elevation-1);
    @include body-bold;
    text-transform: uppercase;
    line-height: 1.4em;
    font-size: 17px;

    &.emph {
      color: var(--highlight-primary-3);
    }
  }
}
