.tournament__head {
  @include wrap;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    flex-direction: row;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

/*
==================================
BREADCRUMBS
==================================
*/

.tournament__head .breadcrumbs {
  @include body-regular;
  font-size: 14px;

  span {
    color: var(--highlight-primary-1);
    white-space: nowrap;
    line-height: 1.4em;
  }

  a,
  a span {
    color: var(--text-1);
    text-decoration: underline;
    white-space: nowrap;
    line-height: 1.4em;
  }

  i.chevron {
    font-size: 0.8em;
    padding: 0;
    margin: 0;
  }
}

/*
==================================
FAVOURITES TOGGLE
==================================
*/
.tournament__head .favorite__toggle {
  @include body-regular;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  a {
    color: var(--text-1);
    font-size: 14px;
  }

  @media screen and (min-width: 767px) {
    margin-top: 0;
  }

  button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    padding: 0;

    i {
      color: var(--highlight-primary-3);
      text-decoration: none;
    }
  }
}
