.tooltip {
  display: inline-block;
  text-transform: capitalize;
  font-weight: normal;
  color: var(--text-3);
  margin: 3px 0;
  cursor: pointer;
  position: relative;

  i {
    font-size: 0.8em;
  }

  &__tip {
    text-transform: lowercase;
    position: absolute;
    min-width: 250px;
    background: var(--text-1);
    color: var(--elevation-1);
    padding: 4px 9px;
    border-radius: 3px;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    cursor: auto;
    visibility: hidden;
    opacity: 0;
    display: none;

    &::after {
      content: "";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--text-1);
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover,
  &:focus,
  &:active {
    .tooltip__tip {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
}
