.edit__tournament__header {
  width: 300px;
  z-index: 40000;
  position: sticky;
  top: calc(38px + 40px);
  height: calc(100vh - 38px - 40px);
  padding-right: 40px;

  button.toggle {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0;
    margin: -1px -1px -1px;
    cursor: pointer;
    border: none;
    background: var(--elevation-1);
    border: 1px solid var(--elevation-5);
    width: 21px;

    i {
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: var(--text-3);
    }
  }

  .header__container {
    background: var(--elevation-1);
    border: 1px solid var(--elevation-5);
    padding: 0 20px;

    // top: calc(42px + 41px + 20px);
    position: sticky;
    min-width: 225px;
    margin-left: -1px;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .col__container {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 0;
      height: 100%;
      width: 100%;
    }

    .tournament__col {
      padding-right: 20px;
    }
  }

  .tournament__header {
    margin-top: 0;
    flex-direction: column-reverse;

    div {
      width: 100%;
    }

    h3 {
      font-size: 17px;
      margin-bottom: 20px;
    }

    .tournament__logo {
      margin-left: 0;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  .tournament__personnel {
    margin: 0 !important;
  }
}

.tournament__navigation {
  margin-top: 20px;

  h3 {
    @include body-bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
    padding-top: 14px;
    border-top: 1px solid var(--elevation-3);
    color: var(--highlight-primary-1);
  }

  ul {
    button {
      background: none;
      border: none;
      padding: 3px 0 3px 10px;
      margin: 0;
      font-size: 14px;
      width: 100%;
      cursor: pointer;
      text-align: left;
      border-left: 2px solid var(--elevation-3);
      transition: 0.2s ease-in-out;

      &:hover,
      &:active,
      &:focus {
        border-left: 5px solid var(--highlight-primary-1);
        color: var(--highlight-primary-1);
      }
    }
  }
}

.section__anchor {
  position: absolute;
  left: 0;
  bottom: calc(100% + (42px + 37px));
  width: 100%;
}

.edit__tournament__header {
  &.collapsed {
    position: fixed;
    right: calc(100% - 250px - 19px);
  }
}
