.preloader {
  width: 100%;
  height: 100%;
  background: var(--elevation-1);

  .dialogue {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 10px 0;
    }
  }

  .logo__container {
    width: 300px;
  }
}

.preloader__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  cursor: not-allowed;
  z-index: 100;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    span {
      display: inline-block;
      @include body-regular;
      font-size: 14px;
      color: var(--text-1);
    }
  }

  .overlay__method {
    display: flex;

    button {
      margin: auto;
      background: none;
      text-decoration: underline;
      border: none;
      cursor: pointer;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
