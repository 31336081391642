.tournaments__toolbar {
  margin-bottom: 40px;
  margin-top: 20px;
  display: flex;

  .label {
    color: var(--text-3);
    text-transform: uppercase;
    @include body-bold;
    font-size: 12px;
    display: block;
    margin-bottom: 7px;
  }

  .toolbar__search {
    margin-right: 20px;
    width: 300px;
    .search__bar {
      background: var(--elevation-1);
    }
  }

  .toolbar__tour {
    @include body-regular;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-right: 20px;
    min-width: 196px;
  }

  .toolbar__dates {
    margin-right: 20px;
    .date__picker {
      background: var(--elevation-1);
    }
  }

  .ui.dropdown {
    border: 1px solid var(--elevation-6);
    border-radius: unset;
  }

  .ui.dropdown i {
    top: 50% !important;
    transform: translateY(-50%);
    line-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .toolbar__bulk {
    @include body-regular;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-right: 20px;
  }
}
