/*
==============================
TAB SELECTORS
==============================
*/
.tab__selectors {
  border: 1px solid var(--elevation-5);
  overflow: hidden;
  background: var(--elevation-1);

  &.is_alternate {
    background: none;
    min-height: unset;
  }

  @media print {
    display: none;
  }

  .selectors__container {
    padding: 10px 10px;
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    cursor: move;

    @media screen and (min-width: 767px) {
      padding: 15px 10px;
    }

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
      display: none; /* Safari and Chrome */
    }
    /* Optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
  }

  button {
    margin-right: 10px;
    user-select: none;
  }

  &.is_alternate .selectors__container {
    padding: 0;
  }

  @media screen and (min-width: 500px) {
    &.is_alternate .selectors__container {
      padding: 10px 10px;
    }
  }
}

/*
==============================
TAB BODY
============================== 
*/

.tab__body {
  background: var(--elevation-1);
  border: 1px solid var(--elevation-5);
  margin-top: -1px;

  @media print {
    border: none;
  }
}

.tab__container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media print {
    position: relative;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    max-width: 100% !important;
    z-index: 7000;
    padding: 0 40px;
  }

  .section__body {
    height: auto;
    flex: 1;
  }
}

.tab__data__content {
  height: 100%;
}

.tab__content {
  display: none;
  height: 100%;

  &.active {
    display: block;
  }

  @media print {
    display: block !important;
    height: auto;
    page-break-after: always !important;
    break-before: page !important;
    page-break-inside: avoid;
  }
}

.tab__container > .tab__content:first-of-type {
  page-break-inside: unset;
}

/*
==============================
TAB SELECTORS - ALTERNATE
==============================
*/

.tab__selectors.is_alternate {
  border: 0;
  margin-top: -5px;
  .selectors__container {
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
  }

  .selectors__flex {
    margin-top: 5px;
    display: flex;
    padding: 0 50px 0 0;
    align-items: center;
    position: relative;
    border: 1px solid var(--elevation-5);
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(251, 251, 251, 1) 35%,
      rgba(237, 237, 237, 1) 100%
    );

    button.print {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    h3 {
      text-transform: uppercase;
      @include heading-bold;
      font-size: 14px;
      margin-right: 20px;
    }

    @media screen and (min-width: 500px) {
      padding: 0 50px 0 20px;
    }
  }
}
