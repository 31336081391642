.file__drag {
  border: 3px dashed var(--elevation-6);
  min-height: 70px;
  margin: 5px 0;
  max-height: 350px;
  overflow-y: auto;

  .upload__prompt {
    text-align: center;
    padding: 20px;
    i {
      font-size: 21px;
      color: var(--elevation-6);
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--text-3);
    }

    span.accepted {
      color: var(--text-3);
      @include body-regular;
      font-size: 12px;
    }
  }
}

.file__preview {
  margin: 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--elevation-5);
  padding: 10px 5px;
  position: relative;
  padding-right: 75px;

  .upload__loader {
    position: absolute;
    top: 3px;
    right: 3px;
  }

  &:hover,
  &:focus {
    span.label__popout {
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
      z-index: 50000;
    }
  }

  span.label__popout {
    position: absolute;
    top: calc(100% - 20px);
    background: var(--text-1);
    color: var(--elevation-1);
    @include body-regular;
    font-size: 12px;
    padding: 5px 7px;
    border-radius: 2px;
    visibility: hidden;
    white-space: nowrap;
    transform: translateY(5px);
    opacity: 0;

    &::after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid var(--text-1);
    }
  }

  p {
    font-size: 14px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .delete__file,
  .upload__file {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;

    i {
      margin: 0;
      padding: 0;
      color: var(--text-3);
    }

    &:hover,
    &:focus {
      & > i {
        color: var(--highlight-primary-1);
      }
    }
  }

  .button__actions {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    .edit__label {
      font-size: 9px;
      background: var(--highlight-primary-1);
      color: var(--elevation-1);
      border: none;
      border-radius: 20px;
      padding: 4px 7px;
      cursor: pointer;

      i {
        margin: 0;
        padding: 0;
      }
    }
  }

  .file__icon {
    position: relative;
    margin-right: 10px;

    i {
      color: var(--highlight-primary-2);
      font-size: 35px;
      margin: 0;
      padding: 0;
    }

    span {
      position: absolute;
      font-size: 10px;
      font-weight: bold;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--elevation-1);
      @include body-regular;
    }
  }

  .file__label {
    padding: 3px;
    margin: 0;
    outline: none;
    min-width: 175px;
    font-size: 14px;
    background: none;

    &.false {
      border: none;
      padding: 0;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .label__actions {
    display: flex;

    button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 5px;
      cursor: pointer;

      i {
        margin: 0;
        padding: 0;
      }
    }
  }

  span.size {
    font-size: 10px;
    color: gray;
  }

  &.large {
    padding: 10px;
    .file__icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        fill: var(--highlight-primary-2);
        width: 50px;
        height: 50px;
      }

      span {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--elevation-1);
      }
    }

    p {
      font-size: 17px;
    }

    span.size {
      font-size: 12px;
      display: block;
      color: gray;
    }
  }
}

.file__input {
  cursor: pointer;
  margin-top: 7px;
  font-size: 14px;

  .browse {
    color: var(--highlight-primary-1);
    text-decoration: underline;
  }
}

.file__thumbnail {
  width: 120px;
  height: 50px;
  margin: 0 3px 0 5px;
  border: 1px solid var(--elevation-6);
  overflow: hidden;
  border-radius: 2px;

  img {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-position: center center;
    object-fit: cover;
  }
}

.drag__drop {
  .drop__prompt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: 0.7);
    z-index: 5000;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      padding: 20px;
      color: var(--elevation-1);
      text-shadow: var(--text-shadow);
      text-align: center;
    }
  }
}

.form__section.uploads {
  width: 100%;
}

.form__section__col.uploads {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;

  .section__input {
    margin: 10px;
    width: 100%;

    @media screen and (min-width: 1440px) {
      width: 47%;
    }
  }
}

.uploaded__files {
  border: 3px solid var(--highlight-primary-1);
  margin: 20px 0 10px 0;

  &.bucket {
    margin: 5px 0 10px 0;
    min-height: 112.5px;
  }

  .bucket__container {
    max-height: 300px;
    overflow-y: auto;
  }

  position: relative;

  .box__label {
    position: absolute;
    left: 5px;
    bottom: 100%;
    transform: translateY(40%);
    background: var(--elevation-2);
    padding: 4px 7px;
    @include body-regular;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: var(--highlight-primary-1);
  }
}

.initializing__loader {
  @include body-bold;
  font-size: 14px;
}
