.tournaments__list {
  margin: 0 20px;
  padding-bottom: 40px;

  .total {
    @include body-regular;
    font-size: 14px;
    display: block;
  }

  .meta__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .pagination {
    margin: 0;
  }

  b {
    font-weight: bold;
  }
}

.tournaments__table {
  .heading,
  .cell {
    padding: 0 10px;
  }

  .cell {
    @include body-regular;
    font-size: 13px;

    span {
      line-height: 1.3em;
    }
  }

  // SET COLUMN WIDTHS
  .checkbox {
    width: 30px;
    display: flex;
    align-items: center;
    margin-top: -9px;
  }

  .title {
    width: 250px;
    display: flex;
  }

  .status {
    width: 100px;

    i {
      margin-right: 7px;
    }
  }

  .start,
  .end {
    width: 150px;
  }

  .location,
  .course {
    width: 175px;
  }

  .personnel {
    width: 175px;
  }

  .table__headings {
    display: flex;
    background: var(--elevation-5);
    padding: 10px 0;
    align-items: center;

    h2 {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .table__row {
    background: var(--elevation-1);
    border-bottom: 1px solid var(--elevation-4);
    padding: 20px 0px;
    display: flex;
    color: var(--text-1);
  }
}
