/*
==================================
CONTAINER
==================================
*/
.tournament__explorer__container {
  @include wrap-mobile;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;

  @media screen and (min-width: 600px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .explorer {
    display: flex;
    padding: 20px;
    flex-direction: column;

    @media screen and (min-width: 767px) {
      padding: 40px;
    }

    @media screen and (min-width: 1000px) {
      flex-direction: row;
    }
  }
}

/*
==================================
TOURNAMENT PREVIEW
==================================
*/

.section__anchor__main.overview {
  position: absolute;
  bottom: calc(100% + (57px + 40px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (74px + 54px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (76px + 54px));
  }
}

.admin__user .section__anchor__main.overview {
  bottom: calc(100% + (34px + 57px + 40px + 31px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (34px + 74px + 54px + 31px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (35px + 76px + 54px + 31px));
  }
}

.default__user.ribbon__active .section__anchor__main.overview {
  bottom: calc(100% + (57px + 40px + 31px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (74px + 54px + 31px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (76px + 54px + 31px));
  }
}

.admin__user.ribbon__active .section__anchor__main.overview {
  bottom: calc(100% + (34px + 57px + 40px + 31px));

  @media screen and (min-width: 600px) {
    bottom: calc(100% + (34px + 74px + 54px + 31px));
  }

  @media screen and (min-width: 800px) {
    bottom: calc(100% + (35px + 76px + 54px + 31px));
  }
}

.explorer__left {
  width: 100%;
  min-height: 200px;
  position: relative;

  transition: 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media screen and (min-width: 767px) {
    flex-direction: row;
    min-width: 450px;
  }

  @media screen and (min-width: 1000px) {
    display: block;
    width: 40%;
    padding-right: 40px;
  }

  @media screen and (min-width: 1300px) {
    min-width: 500px;
  }

  .featured__groups {
    width: 100%;
    margin-top: 20px;

    h4 {
      text-transform: uppercase;
      @include body-bold;
      font-size: 14px;
      color: var(--highlight-primary-2);
      margin-bottom: 10px;

      @media screen and (min-width: 767px) {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 1000px) {
        display: none;
      }
    }

    @media screen and (min-width: 767px) {
      width: 50%;
      padding-left: 20px;
      margin: 0;
    }

    @media screen and (min-width: 1000px) {
      width: 100%;
      padding: 0;
    }
  }

  .current {
    text-transform: uppercase;
    @include body-bold;
    font-size: 14px;
    color: var(--highlight-primary-2);

    button {
      background: none;
      border: none;
      text-transform: uppercase;
      @include body-bold;
      font-size: 14px;
      color: var(--highlight-primary-2);
      cursor: pointer;
      padding: 0;

      i {
        margin: 0;
        padding: 0;
        margin-left: -5px;
      }
    }
  }
}

.tournament__personnel {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 1300px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  // & > div:first-of-type {
  //   margin-right: 40px;
  // }

  div {
    margin-top: 20px;
    margin-right: 20px;

    @media screen and (min-width: 1300px) {
      margin-top: 25px;
    }
  }

  h4 {
    @include body-bold;
    font-size: 14px;
    text-align: left;
    margin-bottom: 7px;
  }

  p {
    margin-top: 0px;
    line-height: 1.4em;
    font-size: 14px;
  }
}

.tournament__header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  h3 {
    @include body-bold;
    font-size: 21px;
    margin-bottom: 40px;
    line-height: 1.4em;
    color: var(--text-1);
  }

  .tournament__logo {
    width: 100%;
    max-width: 150px;
    height: 75px;
    border: 1px solid var(--elevation-6);
    padding: 5px 0;
    margin-left: 40px;

    @media screen and (min-width: 1300px) {
      max-width: 200px;
    }

    div {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & > div:first-of-type {
    width: 60%;
  }
}

.tournament__quick__details {
  li {
    @include body-bold;
    color: var(--text-1);
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.4em;
  }
  i {
    color: var(--text-4);
  }
}

/*
==================================
EXPLORER TOOLBAR
==================================
*/

.explorer__right {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: calc(86px + 54px + 4vh);
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--elevation-3);

  @media screen and (min-width: 1000px) {
    padding-top: 0;
    border-top: none;
    padding-left: 40px;
    margin-top: 0px;
    border-left: 1px solid var(--elevation-3);
  }

  @media screen and (min-width: 1150px) {
    height: calc(95vh - (86px + 54px + 40px));
  }

  .panel__title {
    @include body-bold;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--text-3);
  }

  .explorer__toolbar {
    @include body-regular;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 767px) {
      flex-direction: row-reverse;
    }

    @media screen and (min-width: 1000px) {
      flex-direction: column;
    }

    @media screen and (min-width: 1150px) {
      flex-direction: row-reverse;
    }
  }
}
.pickers {
  display: flex;
  margin-bottom: 10px;

  @media screen and (min-width: 767px) {
    margin-bottom: 0;
  }

  & > .date__picker:first-of-type {
    margin-right: 10px;

    @media screen and (min-width: 1300px) {
      margin-right: 20px;
    }
  }

  .date__picker {
    display: flex;
    border: 1px solid var(--elevation-6);
    align-items: center;
    cursor: pointer;
    padding-right: 5px;
    position: relative;
    width: 50%;

    @media screen and (min-width: 767px) {
      padding-right: 20px;
      width: 100%;
    }

    .clear {
      position: absolute;
      background: none;
      border: none;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        i {
          color: var(--highlight-primary-3);
        }
      }

      i {
        font-size: 14px;
      }
    }

    &:focus,
    &:active {
      border: 1px solid var(--highlight-primary-1);
    }
    i {
      font-size: 14px;
      padding-left: 10px;
      color: var(--elevation-6);

      @media screen and (min-width: 767px) {
        font-size: 20px;
      }
    }

    input {
      box-sizing: border-box;
      border: none;
      background: none;
      padding: 15px 5px;
      padding-left: 10px;
      @include body-bold;
      color: var(--text-1);
      font-size: 12px;
      outline: none;
      width: 100%;
      text-align: left;

      @media screen and (min-width: 767px) {
        font-size: 14px;
        padding: 15px 10px;
        text-align: center;
        max-width: 110px;
        width: unset;
      }
    }
  }
}

.search__bar {
  width: 100%;
  display: flex;

  @media screen and (min-width: 1000px) {
    margin-right: 20px;
    margin-top: 10px;
  }

  @media screen and (min-width: 1150px) {
    margin-top: 0;
  }

  @media screen and (min-width: 1300px) {
    margin-right: 40px;
  }

  .bar {
    display: flex;
    align-items: center;
    border: 1px solid var(--elevation-6);
    width: 100%;
    position: relative;

    @media screen and (min-width: 767px) and (max-width: 1000px) {
      margin-right: 20px;
    }

    i {
      color: var(--elevation-6);
      padding-left: 10px;
    }

    div {
      width: 100%;
    }

    .clear {
      position: absolute;
      background: none;
      border: none;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        i {
          color: var(--highlight-primary-3);
        }
      }

      i {
        font-size: 14px;
      }
    }
  }

  input {
    border: none;
    padding: 15px 10px;
    outline: none;
    margin-left: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
}

/*
==================================
EXPLORER LIST
==================================
*/

.explorer__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  @media screen and (min-width: 1150px) {
    height: 100%;
  }

  ul {
    height: 100%;

    li {
      margin-bottom: 7px;

      @media screen and (min-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }

  .total {
    @include body-regular;
    font-size: 14px;
    padding: 40px 0 20px 0;
    display: block;
  }

  .tournament__card button {
    border: 1px solid var(--elevation-6);
    border-left: 7px solid var(--highlight-primary-1);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: var(--elevation-1);
    width: 100%;
    text-transform: unset;
    letter-spacing: unset;
    cursor: pointer;
    height: 100%;
    max-height: auto;

    @media screen and (min-width: 1150px) {
      max-height: 8vh;
    }

    .tournament__info {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      flex-direction: column;

      @media screen and (min-width: 1050px) {
        flex-direction: row;
      }

      h4 {
        @include heading-regular;
        color: var(--text-1);
        text-align: left;
        margin-right: 10px;
        font-size: 14px;

        @media screen and (min-width: 1400px) {
          font-size: 17px;
        }
      }
    }

    p.date__range {
      font-size: 12px;
      @include body-regular;
      text-align: left;
      white-space: nowrap;
      display: flex;
      margin-top: 3px;

      @media screen and (min-width: 1050px) {
        text-align: right;
        @include body-bold;
        margin-top: 0px;
      }

      @media screen and (min-width: 1300px) {
        font-size: 14px;
      }

      i {
        font-size: 14px;
        color: var(--elevation-6);

        @media screen and (min-width: 1050px) {
          font-size: 17px;
        }
      }
    }
  }
}

.tournament__logo.list {
  max-width: 50px;
  min-width: 50px;
  height: 3vh;
  // flex-grow: 1;
  border: 1px solid var(--elevation-4);
  padding: 1px;
  margin-right: 20px;
  // display: none;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}
