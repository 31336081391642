@mixin vertical-spacing {
  margin-top: 7px;
  margin-bottom: 15px;
}

@mixin vertical-spacing-heading {
  margin-top: 20px;
  margin-bottom: 7px;
}

.rich__text {
  strong {
    font-weight: bold;
  }

  a {
    word-break: break-all;
  }

  & > :first-child {
    margin-top: 0;
    padding-top: 0;
  }

  p {
    font-size: 14px;
    line-height: 1.4em;
    @include vertical-spacing;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @include body-bold;
    @include vertical-spacing-heading;
    line-height: 1.4em;
  }

  b {
    @include body-bold;
    display: block;
  }

  & > :last-child {
    margin-bottom: 0 !important;
  }

  li {
    @include body-regular;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;
    @include vertical-spacing;
  }

  ol {
    padding-left: 20px;
    list-style-type: decimal;
  }
}
