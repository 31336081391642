.empty__dialogue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .dialogue__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h3 {
      @include heading-bold;
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 10px;
      color: var(--text-3);
      padding: 0 !important;
    }

    p {
      font-size: 12px;
      color: var(--text-3);
      margin-top: 5px;
    }

    i {
      font-size: 30px;
      color: var(--elevation-6);
    }
  }

  @media print {
    display: none;
  }
}
