/*
==================================
TEXT EDITOR
==================================
*/
.rdw-editor-main {
  border: 1px solid var(--elevation-5);
  background: var(--elevation-1);
  margin-top: -1px;
  padding: 10px;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  & > * {
    line-height: 1.4em;
  }
}

.field__set.small .rdw-editor-main {
  min-height: 75px;
  // height: 100%;
  // max-height: 100px;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
  border: 1px solid var(--elevation-5);
}

.rdw-dropdown-selectedtext span {
  color: var(--text-1);
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0;
}

.rdw-editor-wrapper {
  max-width: 100%;
  @include body-regular;
  color: var(--text-1);
}

.rdw-colorpicker-option,
.rdw-colorpicker-cube {
  border-radius: 50%;
}

.rdw-colorpicker-modal-options {
  overflow-x: hidden;
  border: 1px solid var(--elevation-3);
}

/*
==================================
SCRIPTS
==================================
*/

.form__section.scripts {
  width: 100%;
}

.form__section__col.scripts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;

  .section__input {
    margin: 10px;
    width: 100%;

    @media screen and (min-width: 1440px) {
      width: 47%;
    }
  }
}

/*
==================================
DAILY SCHEDULE
==================================
*/

.form__section.daily__schedule {
  width: 100%;
}
