.scrolling__ticker {
  position: relative;
  border-bottom: 1px solid var(--elevation-5);
  height: 40px;

  @media screen and (min-width: 600px) {
    height: 55px;
  }

  .ticker__panel {
    position: absolute;
    background: white;
    width: 27.5%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--elevation-5);

    .panel__meta {
      display: flex;
      align-items: center;

      h3 {
        @include body-bold;
        font-size: 12px;
        text-transform: uppercase;
        background: var(--highlight-primary-3);
        padding: 7px 7px 5px 7px;
        color: var(--elevation-1);

        @media screen and (min-width: 600px) {
          display: inline-block;
          background: none;
          padding: 0;
          color: var(--text-1);
        }
      }

      span.label {
        display: none;
        background: var(--highlight-primary-3);
        color: var(--elevation-1);
        @include body-regular;
        font-size: 12px;
        padding: 5px 7px;
        margin-left: 5px;

        @media screen and (min-width: 600px) {
          display: inline-block;
        }
      }
    }

    &.left {
      left: 0;
      top: 0;
      justify-content: flex-end;
      width: 175px;
      box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.115);

      @media screen and (min-width: 600px) {
        width: 250px;
      }

      @media screen and (min-width: 900px) {
        width: 27.5%;
        min-width: 200px;
      }

      @media screen and (min-width: 1200px) {
        width: 20%;
      }
    }

    &.right {
      right: 0;
      top: 0;
      display: none;
      box-shadow: -2px 0px 5px 2px rgba(0, 0, 0, 0.115);

      @media screen and (min-width: 1200px) {
        width: 20%;
        display: block;
      }
    }
  }
}

.ticker__container {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  width: calc(100% - 175px);
  overflow-x: auto;
  display: flex;
  background: var(--elevation-2);

  @media screen and (min-width: 600px) {
    left: unset;
    right: 0;
    width: calc(100% - 250px);
  }

  @media screen and (min-width: 900px) {
    width: calc(100% - 27.5%);
  }

  @media screen and (min-width: 1200px) {
    width: calc(100% - (20% + 20%));
    right: unset;
    transform: translateX(-50%);
    left: 50%;
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  cursor: move;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
    display: none; /* Safari and Chrome */
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
}

.ticker__cell {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(251, 251, 251, 1) 35%,
    rgba(237, 237, 237, 1) 100%
  );
  border: 1px solid var(--elevation-5);
  border-bottom: 5px solid var(--highlight-primary-3);
  margin-right: 5px;
  padding: 7px 15px;
  white-space: nowrap;
  cursor: move;

  h4 {
    @include body-bold;
    color: var(--text-2);
    font-size: 12px;
    line-height: 0.5em;
    cursor: pointer;

    @media screen and (min-width: 600px) {
      font-size: 14px;
      line-height: 1em;
    }
  }

  span.sub {
    @include body-regular;
    color: var(--text-2);
    font-size: 10px;
    line-height: 0.5em;

    @media screen and (min-width: 600px) {
      font-size: 12px;
      line-height: 1em;
    }
  }
}

.ticker__wrap {
  display: flex;
}

.minimal__select {
  position: relative;
  font-size: 10px;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    font-size: 12px;
  }

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none !important;
    outline: none;
    padding-right: 25px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
    cursor: pointer;
    color: var(--text-1);

    option {
      color: var(--text-1) !important;
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }
}
