.tournament__navigator {
  @include wrap;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.tournament__prev {
  text-align: left;
  margin-right: 20px;
}

.tournament__next {
  text-align: right;
  margin-left: 20px;
}

.tournament__prev,
.tournament__next {
  display: flex;
  background: none;
  border: none;
  align-items: center;
  cursor: pointer;

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  i {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: var(--highlight-primary-1);
  }

  span.subtitle {
    @include body-regular;
    color: var(--highlight-primary-1);
    font-size: 12px;

    @media screen and (min-width: 500px) {
      font-size: 14px;
    }
  }

  h5 {
    @include body-bold;
    color: var(--text-1);
    margin: 3px 0;
    font-size: 14px;

    @media screen and (min-width: 500px) {
      font-size: 17px;
    }
  }

  span.date {
    @include body-regular;
    font-size: 12px;
    color: var(--text-1);

    @media screen and (min-width: 500px) {
      font-size: 14px;
    }
  }
}
