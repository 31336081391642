.tour__name {
  @include body-regular;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--highlight-primary-1);
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
}

.tournament__overview {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: auto;
  padding: 0 10px;

  @media screen and (min-width: 600px) {
    padding: 0 25px;
  }

  @media screen and (min-width: 1300px) {
    flex-direction: row;
  }
}

.tournament__overview__sidebar {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 930px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 0;
    flex-direction: column;
    width: 450px;
  }

  .memorandums {
    @media print {
      display: none !important;
    }
  }
}

.tournament__overview__overview {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 1300px) {
    width: auto;
    height: 100%;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1500px) {
    margin-bottom: 40px;
  }

  .overview__content {
    height: 100%;
    background: var(--elevation-1);
    border: 1px solid var(--elevation-5);
    padding: 0 20px 20px 20px;

    @media print {
      border: none;
    }
  }
}

.tournament__overview__timesheets {
  max-height: 500px;
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: 930px) {
    margin-top: 0;
    margin-left: 20px;
    width: 20%;
    min-width: 450px;
  }

  @media screen and (min-width: 1300px) {
    width: auto;
    margin-left: 0;
  }

  .timesheet__content {
    min-height: 300px;
    background: var(--elevation-1);
    border: 1px solid var(--elevation-5);
    position: relative;
  }
}

.tournament__overview__main {
  width: 100%;

  @media screen and (min-width: 1300px) {
    margin-left: 20px;
  }

  @media screen and (min-width: 1500px) {
    margin-left: 40px;
  }
}

.tournament__overview__main__grid {
  min-height: 100vh;
  height: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  // grid-template-rows: auto;

  @media screen and (min-width: 1500px) {
    grid-gap: 40px;
  }
}

// GRID CONFIGURATION
.tournament__overview__schedule__onsite,
.tournament__overview__stream__details {
  grid-column: 1 / span 12;
  max-height: 450px;
  min-height: 300px;

  @media screen and (min-width: 930px) {
    grid-column: 1 / span 6;
  }
}

.tournament__overview__schedule__wf {
  grid-column: 1 / span 12;

  max-height: 450px;
  min-height: 300px;
}

.tournament__overview__schedule__pgate,
.tournament__overview__stream__assignments {
  grid-column: 1 / span 12;
  max-height: 450px;
  min-height: 300px;

  @media screen and (min-width: 930px) {
    grid-column: 7 / span 6;
  }
}

.tournament__overview__documents {
  grid-column: 1 / span 12;
}

// SECTION HEIGHTS
.tournament__overview__schedule__onsite,
.tournament__overview__schedule__pgate,
.tournament__overview__stream__details,
.tournament__overview__stream__assignments {
  .tab__body {
    min-height: 300px !important;
    // max-height: 350px;
  }
}

.tournament__overview__documents .section__body {
  min-height: 390px;
  max-height: 50vh;
  overflow-x: hidden;
}
