/*
===================================
FORM AND COMPONENTS
===================================
*/

.form {
  width: 100%;
  position: relative;

  .save__btn {
    background: var(--highlight-primary-1);
    color: var(--elevation-1);
    padding: 7px 10px;
    border: none;
    width: 100%;
    position: relative;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    transition: 0.2s ease-in-out;

    .initializing__loader {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover,
    &:focus {
      background: var(--highlight-primary-2);
    }

    span {
      display: block;
      padding-left: 10px;
    }

    i {
      position: absolute;
      left: 10px;
    }
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .ui.input input {
      background: var(--elevation-2);
      box-sizing: border-box;
      width: 100%;
    }
  }

  .req {
    color: var(--danger-1);
    margin-left: 2px;
  }

  input {
    width: 100%;
    padding: 9px 10px;
    margin-bottom: 15px;
    background: var(--elevation-2);
    border: var(--elevation-6) 1px solid;
    border-radius: 2px;
  }

  .helper__icon {
    margin-left: 5px;
    color: var(--text-3);
    cursor: pointer;
    position: relative;

    .ui.basic.label {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      text-align: center;
      line-height: 1.4em;
    }

    &:active,
    &:focus,
    &:hover {
      .ui.basic.label {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .form__submit {
    width: 100%;
    display: flex;
    // @include h-wrapper-small;
    // @include v-wrapper-small;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    justify-content: flex-end;
    // max-width: 490px;
  }
}

.form__section {
  display: flex;
  border: none;
  border-bottom: 2px solid var(--elevation-5);
  flex-direction: row;
  padding-bottom: 20px;
  width: 500px;

  .section__input {
    margin-bottom: 40px;
  }

  .input__label {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 17px;
  }

  & > :nth-child(1) {
    width: 100%;
    margin-right: 30px;
    margin-top: 20px;
    max-width: 150px;
    // position: sticky;
    // top: calc(42px + 41px + 20px);
    align-self: flex-start;
    top: 85px;
    position: sticky;
    height: 100px;
    margin-bottom: 15px;

    label {
      font-weight: bold;
      font-size: 14px;
      padding-bottom: 20px;
      line-height: 1.4em;
    }
  }

  & > :nth-child(2) {
    width: 100%;
    margin-top: 20px;
  }

  label {
    margin-bottom: 5px;
    margin-top: 7px;
    display: block;
    @include body-regular;
  }

  input {
    width: 100%;
    padding: 9px 10px;
    margin-bottom: 10px;
    background: var(--elevation-1);
    border: var(--elevation-6) 1px solid;
    border-radius: 2px;
  }
}

.form__submit {
  margin-top: 10px;

  .col {
    display: flex;
  }
}

.section__input {
  margin: 10px;
}

.empty__list {
  width: 100%;
  min-height: 70px;
  position: relative;
  padding: 10px;
  margin: 10px 0;
}

.field__label {
  font-size: 14px;
}

.field__set.submit {
  display: flex;
  justify-content: flex-end;

  button.submit {
    background: var(--highlight-primary-1);
    color: var(--elevation-1);
    border-radius: 3px;
    border: none;
    padding: 7px 7px;
    cursor: pointer;
    font-size: 14px;

    i {
      font-size: 12px;
    }
  }

  button.cancel {
    background: none;
    border: none;
    font-size: 14px;
    margin-right: 7px;
    cursor: pointer;
  }
}

/*
===================================
FORM LAYOUT
===================================
*/

.form__section__col.dbl__full {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;

  &.reverse__wrap {
    flex-wrap: wrap-reverse;

    @media screen and (min-width: 1440px) {
      flex-wrap: wrap;
    }
  }

  .section__input {
    margin: 10px;
    width: 100%;

    @media screen and (min-width: 1440px) {
      width: 47%;
    }
  }
}

.form__section__col.dbl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;

  .section__input {
    margin: 10px;
    width: 100%;
    max-width: calc(47% - 10px);
  }
}

.form__section__col.full {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;

  .section__input {
    width: 100%;
  }
}

/*
===================================
WARNINGS
===================================
*/

form.form .form__warnings {
  margin-top: 10px;
  li {
    @include body-regular;
    border: 1px solid var(--danger-1);
    background: var(--danger-3);
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: var(--danger-1);
    font-size: 14px;

    .code {
      font-weight: bold;
    }
  }
}

/*
===================================
CUSTOM SELECT
===================================
*/

select.custom {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  width: 100%;
  padding: 9px 30px 9px 10px;
  background: var(--elevation-2);
  border: var(--elevation-6) 1px solid;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  background: var(--elevation-1);
  @include body-regular;

  option {
    @include body-regular;
  }

  option:first-child {
    color: green !important;
  }
}

.select {
  position: relative;
  margin-bottom: 15px;
  background: var(--elevation-1);
  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--text-1);
    cursor: pointer;
  }
}

select.custom::-ms-expand {
  display: none;
}
